/*.App {
  text-align: center;
}*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-cid-blue {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.button-cid-blue:hover {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  opacity: 0.9;
}
.pad-cid-button {
  padding: 8px 16px 8px 16px;
}

.cid-button-gray-400 {
  color: black !important;
  background-color: #F0F0F0;
  border: 1px solid;
  border-color: rgba(167, 172, 177, 1);
  border-radius: 5px;
}

.cid-button-gray-400:hover{
  background-color: #e2e2e2;
}

.btn-border {
  border: 1px solid ;
}
.btn-border:hover {
  border: 1px solid ;
}

.mt-20{
  margin-top: 20px;
}
.mt-30{
  margin-top: 30px;
}
.mt-40{
  margin-top: 40px;
}
.mt-80{
  margin-top: 80px;
}

.mt-2rm{
  margin-top: 2rem;
}

.title-pages {
  font-size: 30px;
  line-height: 24px;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}
